<template>
	<phi-page>
		<div slot="toolbar">
			<mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
			<h1>{{ $t('noun.targets') }}</h1>
		</div>

		<div class="phi-card">
            <template v-for="target in targets.items">
                <router-link
                    :key="target.id"
                    :to="{name: 'geo-tracker', params:{targetId: target.id}}"
                    class="phi-media"
                    v-if="target.name"
                >
                    <div class="phi-media-figure">
                        <img :src="target.icon"/>
                    </div>			
                    <div class="phi-media-body">{{ target.name }}</div>
                    <div class="phi-media-right">
                        <mu-icon v-if="!isSubscribed(target)" value="add" @click.prevent="subscribe(target)"></mu-icon>
                    </div>
                </router-link>
            </template>

			<phi-drawer :open="!tpl.creatorIsOpen">
				<div class="phi-media" @click="tpl.creatorIsOpen = true">
					<mu-icon class="phi-media-figure" value="add"></mu-icon>
					<div class="phi-media-body">{{ $t('action.addVehicle') }}</div>
				</div>
			</phi-drawer>

			<phi-drawer :open="tpl.creatorIsOpen">
				<fieldset>
					<phi-input v-model="newTracker.type" label="type"></phi-input>
					<phi-input v-model="newTracker.identifier" label="identifier"></phi-input>
					<phi-input v-model="newTracker.name" label="name"></phi-input>
				</fieldset>

				<div class="alert" v-if="unlock">{{ $t('noun.allFieldsMustBeCompleted') }}*</div>

				<footer>
					<div :class="['phi-media save action', !newTracker.name ? 'disabled' : '']" @click="createTarget">
						<mu-icon class="phi-media-figure" value="save"></mu-icon>
						<div class="phi-media-body">{{ $t('action.save') }}</div>
					</div>

					<div class="phi-media cancel action" @click="tpl.creatorIsOpen = false">
						<mu-icon class="phi-media-figure" value="clear"></mu-icon>
						<div class="phi-media-body">{{ $t('action.cancel') }}</div>
					</div>
				</footer>
			</phi-drawer>
		</div>

		<label>{{ $t('noun.mySubscriptions') }}</label>
		<div class="phi-card">
			<div v-for="subscription in subscriptions.items" :key="subscription.id" class="phi-media">
				<div class="phi-media-figure">
					<img :src="subscription.target.icon"/>
				</div>	
				<div class="phi-media-body">{{ subscription.target.name}}</div>
				<div class="phi-media-right">
					<mu-icon class="phi-media-figure" value="remove" @click="unsubscribe(subscription)"></mu-icon>
				</div>
			</div>
		</div>

	</phi-page>
</template>

<script>
import app from '../../store/app.js';

export default {
	data() {
		return {
			targets: app.api.collection("/geo/targets"),
			subscriptions: app.api.collection(`/people/${app.user.id}/geo/subscriptions`),
			newTracker: {
				name: '',
				type: '',
				identifier: ''
			},
			tpl: {
				creatorIsOpen: false
			},
			unlock: false
		}
	},

	methods: {
		createTarget() {
			if (this.newTracker.identifier && this.newTracker.name && this.newTracker.type) {
				app.api
					.post("/geo/targets", this.newTracker)
					.then(tracker => {
						this.targets.push(tracker);
						this.newTracker = {
							name: '',
							type: '',
							identifier: ''
						};
						this.tpl.creatorIsOpen = false;
					});

				this.unlock = false;
			} else {
				this.unlock = true;
			}
		},

		subscribe(target) {
			app.api
				.put(`/people/${app.user.id}/geo/subscriptions/${target.id}`)
				.then(data => console.log(data));
		},

		unsubscribe(subscription) {
			app.api
				.delete(`/people/${app.user.id}/geo/subscriptions/${subscription.target.id}`)
				.then(this.subscriptions.splice(subscription));
		},

		isSubscribed(target) {
			return this.subscriptions.items.some(subscription => subscription.target.id == target.id );
		}
	},

	mounted() {
		this.targets.fetch();
		this.subscriptions.fetch();
	}
}
</script>

<style lang="scss" scoped>
footer {
	display: flex;

	.action {
		transition: all .5s ease;
		&:hover {
			background: rgba(0,0,0,.08);
		}
	}

	.cancel {
		margin-left: 20px;
	}
}

fieldset {
	margin: 0;
	border: 0;
	padding: 14px 16px;

	.phi-input {
		margin-right: 8px;
	}
}

.phi-card {
	margin-bottom: 18px;
}

.disabled {
	opacity: 0.4;
}

.alert {
	color: orange;
	font-size: 12px;
	margin-left: 15px;
}
</style>
